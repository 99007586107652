

.App {
  background-size: cover;
  text-align: center;
  background-size: auto;
  display: flex;
  flex-wrap: wrap;
}

a:link, a:visited {
  background-color: rgb(18, 255, 34);
  color:#0f15c7;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

a:hover, a:active {
  background-color: #0f15c7;
  color: rgb(18, 255, 34);
}

.topstuff{
  background-color: #CCCCFF;
  margin-top: 1%;
  align-self: flex-start;
  text-align: center; 
  width:100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(5px + 2vmin);
  color: rgb(0, 0, 0);
  width: 100%;
  /* background-color: #0f15c7; */
}

.content{
  color: rgb(0, 0, 0);
 border: thick double  #CCCCFF;
  background-color: #C1E1C1;
  border-radius: 3px;
  margin-top: 2.5%;
margin-left: 2.5%;
margin-right: 2.5%;
margin-bottom: 10%;
min-width: 400px;
}

.link{
    text-decoration-line: underline;
    text-decoration-color: green;

}

#root{
  height:100vh;
  background-image: url("headshot.jpeg");
}